
import {  Grid } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { ProcessConfirm } from './processConfirm';
import { ProcessUpload } from './processUpload';
import { ProcessValidation } from './processValidation';
import { PreImportTicketExtended, TicketImportSummarization } from './types';
import CustomStepper from '../../core/stepper';
const testId = 'ticket-import-process';
const TicketImportProcessPage:React.FunctionComponent = () => {


  const [processState, setProcessState] = useState(0);
  const [validationData, setValidationData] = useState<PreImportTicketExtended[]>([]);
  const [importSummarization, setImportSummarization] = useState<TicketImportSummarization>({});
  const [fileId, setFileId] = useState<number | null>(null);

  const steps = ['Upload file', 'Validate', 'Confirm'];

  return (
    <Fragment>
      <Grid container justifyContent={ 'center' } mt={ 2 }>
        <Grid item xs={ 12 } lg={ 12 }>
          <CustomStepper testId={ testId } steps={ steps } activeStep={ processState } />
        </Grid>
      </Grid>
      <Grid>
      </Grid>
      { processState === 0 && <ProcessUpload 
        setImportSummarization={ setImportSummarization } 
        setProcessState={ setProcessState } 
        setValidationData={ setValidationData } 
        setFileId={ setFileId } /> }
      { processState === 1 && <ProcessValidation validationData={ validationData } /> }
      { processState === 2 && <ProcessConfirm importSummarization={ importSummarization } fileId={ fileId }></ProcessConfirm> }
    </Fragment>
  );
};

export default TicketImportProcessPage;
